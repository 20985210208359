import {XhrService} from "../ts/xhr.service";
import {appendScriptToDOM} from "./utilities/functions";
import {showModal, generateNewModal} from "./modal";
import {getNewFlashmessage} from "./flashmessages";

function sendFormApi(event) {
    event.preventDefault();

    let formEl = event.target;
    let ajax_fn = formEl.getAttribute("data-ajax-function");
    let loader_selector = formEl.getAttribute("data-ajax-loader");

    // set loader
    if (loader_selector) {
        const loaderElements = document.querySelectorAll(loader_selector);
        loaderElements.forEach((loaderElement) => {
            //addLoadingClass(loaderEl);
        });
    }

    // create FormData
    let formData = new FormData(formEl);
    // append pressed button to the form
    if (event.submitter && event.submitter.getAttribute("name")) {
        formData.append(event.submitter.getAttribute("name"), event.submitter.getAttribute("value"));
    }

    XhrService.generalAjaxCall(ajax_fn, formData, 'POST', 'json').then(response => {
        if (!response.status) {
            throw "Unable to handle response from GeneralAjaxDataResponse";
        }

        for (const htmlToDom of response.htmlToDom) {
            let domTargets = document.querySelectorAll(htmlToDom.domTargetSelector);
            domTargets.forEach((domTargetSelector) => {
                domTargetSelector.innerHTML = htmlToDom.html;
            });
        }

        if (response.status === "error" && response.errorMessage !== "") {
            /*
            response.modalData = {
                "title": "",
                "text": response.errorMessage,
                "modalClasses": ["modal--error"]
            }
            */
        }

        if (response.modalData && response.modalData.html) {
            const title = response.modalData.title;
            const text = response.modalData.html;

            const modal = generateNewModal("modalTemplate");
            if (modal) {
                modal.classList.remove("modal--loading");
                for (const modalClass of response.modalData.modalClasses) {
                    modal.classList.add(modalClass);
                }

                let modalBody = modal.querySelector('.modal__body');
                let modalTitle = modal.querySelector('.modal__title');
                if (modalTitle) {
                    modalTitle.innerHTML = title;
                }
                if (modalBody) {
                    modalBody.innerHTML = "<div>" + text + "</div>";
                }
                showModal(modal);
            }
        }

        for (const scriptToDom of response.scriptToDom) {
            appendScriptToDOM(scriptToDom.scriptText, scriptToDom.addToBody);
        }

        for (const [messageType, listOfMessages] of Object.entries(response.flashMessages)) {
            // for type definition see SessionFlashMessageBag.php
            let mappingMessageTypToInt = {
                "info": 0,
                "notice": 0,
                "warning": 2,
                "error": 3,
                "success": 1,
            };
            let messageTypeInt = mappingMessageTypToInt[messageType];

            for (const message of listOfMessages) {
                getNewFlashmessage(message, messageTypeInt);
            }
        }

        return response.status;
    });

}

export function initAjaxFormApi() {
    document.addEventListener('submit', evt => {
        if (evt.target.classList.contains('js-ajaxFormSend')) {
            sendFormApi(evt);
        }
    });
}