declare const siteCode: string
declare const languageCode: string

export class FetchService {
    static controller = new AbortController()

    static async get<T>(url: string): Promise<IApiResponse<T>> {
        const response = await fetch(url, {
            method: 'GET',
            headers: this.getHeaders(),
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            signal: this.controller.signal
        })
        return response.json()
    }

    static async delete<T>(url: string): Promise<IApiResponse<T>> {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: this.getHeaders(),
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            signal: this.controller.signal
        })
        return response.json()
    }

    static async post<T>(url: string, data: any): Promise<IApiResponse<T>> {
        const response = await fetch(url, {
            method: 'POST',
            headers: this.getHeaders(),
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: this.preparePayload(data), // body data type must match 'Content-Type' header
            signal: this.controller.signal
        })
        return response.json()
    }

    static async put<T>(url: string, data: any): Promise<IApiResponse<T>> {
        const response = await fetch(url, {
            method: 'PUT',
            headers: this.getHeaders(),
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: this.preparePayload(data), // body data type must match 'Content-Type' header
            signal: this.controller.signal
        })
        return response.json()
    }

    static async patch<T>(url: string, data: any): Promise<IApiResponse<T>> {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: this.getHeaders(),
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: this.preparePayload(data), // body data type must match 'Content-Type' header
            signal: this.controller.signal
        })
        return response.json()
    }

    static cancelCall() {
        this.controller.abort()
    }

    private static getSessionCookie(): string {
        const cookieName = `sid${siteCode}`
        if (document.cookie.length > 0) {
            let cStart = document.cookie.indexOf(`${cookieName}=`);
            if (cStart != -1) {
                cStart = cStart + cookieName.length + 1;
                let cEnd = document.cookie.indexOf(';', cStart);
                if (cEnd == -1) {
                    cEnd = document.cookie.length;
                }
                return document.cookie.substring(cStart, cEnd);
            }
        }
        return document.body.dataset.sessionId
    }

    private static getHeaders(): Headers {
        const headers = new Headers()
        headers.set('x-site-code', siteCode)
        headers.set('x-language-code', languageCode)
        headers.set('Content-Type', 'application/json')

        const sessionId = this.getSessionCookie()
        if (sessionId) {
            headers.set('x-session-id', sessionId)
        }
        return headers
    }

    private static preparePayload(data: any): Blob | FormData | URLSearchParams | string {
        if (data instanceof FormData || data instanceof Blob || data instanceof URLSearchParams || typeof data === 'string') {
            return data
        } else {
            return JSON.stringify(data)
        }
    }

}

export interface IApiResponse<T> {
    success: boolean
    message?: string
    data: T
}

