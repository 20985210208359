import {showModal,} from "./modal";

export function generateFlyoutMenu (title='Menu', flyoutContent = '', flyoutID = 'flyoutMenu',callback = ()=>{}) {

        const template = document.getElementById('modalTemplate');
        if (template) {
            const newModal = document.body.appendChild(template.content.cloneNode(true).querySelector('.modal'));
            newModal.setAttribute("id", flyoutID);
            newModal.querySelector('.modal__title').innerHTML = title;
            newModal.querySelector('.modal__body').innerHTML = flyoutContent.innerHTML;
            newModal.classList.remove('modal--loading');
            callback(newModal);
            return newModal;
        } else {
            console.error("Cannot find Modal Template");
            return false;
        }

}

export function initFlyout() {

    document.body.addEventListener('click', (e) => {

        const flyout = e.target.closest('[data-flyout]');

        if (flyout !== null) {

            showModal(document.getElementById(flyout.dataset.flyout),()=>{},{
                type: "flyout",
                direction: flyout.dataset.direction
            });
            e.preventDefault();

        }

    });

}
