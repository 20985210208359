import {slideDown, slideUp} from "./utilities/slide";

function switchDCOrderMotive(img, id, targets) {
    let dcOrderSelectedMotive = document.querySelector('.js-dcOrderSelectedMotive');
    let dcOrderinputSelectedMotive = document.querySelector('.js-dcOrderInputSelectedMotive');

    if (dcOrderinputSelectedMotive && dcOrderinputSelectedMotive) {
        dcOrderSelectedMotive.src = img;
        dcOrderinputSelectedMotive.value = id;
        for (let target of targets) {
            target.classList.remove("is-active");
            if (target.dataset.id) {
                if (target.dataset.id === id) {
                    target.classList.add("is-active");
                }
            }
        }
    }
}

function switchDCOrderTag(id, targets, individual = false) {
    let dcOrderinputTag = document.querySelector('.js-dcOrderTagInput');
    let dcOrderIndividualinput = document.querySelector('.js-dcOrderIndividualInput');

    if (dcOrderinputTag) {
        dcOrderinputTag.value = id;
        for (let target of targets) {
            target.classList.remove("is-active");
            if (target.dataset.id) {
                if (target.dataset.id === id) {
                    target.classList.add("is-active");
                }
            }
        }
    }
    if (dcOrderIndividualinput && !individual) {
        dcOrderIndividualinput.value = "";
    }
}

function initSwitchDcOrderMotive() {
    let dcOrderSwitchTargets = document.querySelectorAll('.js-dcOrderSwitchMotive');
    if (dcOrderSwitchTargets) {
        for (let target of dcOrderSwitchTargets) {
            target.addEventListener("click", function () {
                if (target.dataset.bigImage && target.dataset.id) {
                    switchDCOrderMotive(target.dataset.bigImage, target.dataset.id, dcOrderSwitchTargets);
                }
            });
        }
    }
}

function initSwitchDcOrderTags() {
    let dcOrderTags = document.querySelectorAll('.js-dcOrderTag');
    if (dcOrderTags) {
        for (let target of dcOrderTags) {
            if(target.classList.contains('is-active')) {
                document.querySelector('.js-dcOrderTagInput').value = target.dataset.id;
            }
            target.addEventListener("click", function () {
                if (target.dataset.id) {
                    switchDCOrderTag(target.dataset.id, dcOrderTags);
                }
            });
        }
    }
}

function initIndividualDcOrderInput() {
    let dcOrderIndividualinput = document.querySelector('.js-dcOrderIndividualInput');
    let dcOrderTags = document.querySelectorAll('.js-dcOrderTag');
    if (dcOrderIndividualinput) {
        dcOrderIndividualinput.addEventListener("keyup", function () {
            switchDCOrderTag(0, dcOrderTags, true);
        });
    }
}

function initToggleOrderMailSlide(query = document.querySelectorAll(".js-dcOrderMailToggleLink")) {
    for (let toggle of query) {
        toggle.addEventListener("click", function () {
            let dataset = toggle.dataset;
            let toggleTar = "";
            let wrapper = toggle.closest('.js-dcOrderMailToggle');

            if (dataset.toggleTarget) {
                toggleTar = dataset.toggleTarget;
            } else {
                console.error("toggle targets not defined");
                return;
            }

            if (wrapper === null) {
                wrapper = document;
            }

            for (let target of (wrapper.querySelectorAll('.js-dcOrderMailInput'))) {
                if (target.classList.contains(toggleTar)) {
                    slideDown(target, 200, "linear", function () {
                        let input = target.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    });
                } else {
                    slideUp(target, 200, "linear");
                }
            }
        });
    }
}

function initDcOrder(){
    console.log('tetetsetsetset');
    initSwitchDcOrderMotive();
    initSwitchDcOrderTags();
    initIndividualDcOrderInput();
    initToggleOrderMailSlide();
}

export default initDcOrder;